import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import introductionfile from "../content/formacao.md";
import curso1file from "../content/curso1.md";
import curso2file from "../content/curso2.md";
import curso4file from "../content/curso4.md";
import curso5file from "../content/curso5.md";
import footnotesfile from "../content/formacaonotas.md";
import livros24k from "../static/livros2_4k.jpg";
import livros2fhd from "../static/livros2_fhd.jpg";
import livros2hd from "../static/livros2_hd.jpg";
import livros21600 from "../static/livros2_1600.jpg";
import livros21366 from "../static/livros2_1366.jpg";
import livros21024 from "../static/livros2_1024.jpg";
import livros2768 from "../static/livros2_768.jpg";
import livros2640 from "../static/livros2_640.jpg";
import bonecos from "../static/bonecos.jpg";
//ReactGA.pageview("/formacao");

const Teaching = function () {
  const [introduction, setIntroduction] = useState({ textcontent: null });
  const [curso1, setCurso1] = useState({ textcontent: null });
  const [curso2, setCurso2] = useState({ textcontent: null });
  const [curso4, setCurso4] = useState({ textcontent: null });
  const [curso5, setCurso5] = useState({ textcontent: null });
  const [footnotes, setFootnotes] = useState({ textcontent: null });

  const changeHeight = function () {
    const overlaydom = document.querySelector(".teachingoverlay");
    if (overlaydom !== null) {
      overlaydom.style.opacity = "1";
    }
  };

  useEffect(() => {
    setTimeout(changeHeight, 500);
  }, []);

  useEffect(() => {
    document.title = "Formação | João Vilas Boas Sousa Advogados";
  }, []);
  useEffect(() => {
    fetch(introductionfile)
      .then((response) => response.text())
      .then((textextracted) => {
        setIntroduction({ textcontent: textextracted });
      });
  }, []);

  useEffect(() => {
    fetch(footnotesfile)
      .then((response) => response.text())
      .then((textextracted) => {
        setFootnotes({ textcontent: textextracted });
      });
  }, []);
  useEffect(() => {
    fetch(curso1file)
      .then((response) => response.text())
      .then((textextracted) => {
        setCurso1({ textcontent: textextracted });
      });
  }, []);

  useEffect(() => {
    fetch(curso2file)
      .then((response) => response.text())
      .then((textextracted) => {
        setCurso2({ textcontent: textextracted });
      });
  }, []);

  useEffect(() => {
    fetch(curso4file)
      .then((response) => response.text())
      .then((textextracted) => {
        setCurso4({ textcontent: textextracted });
      });
  }, []);

  useEffect(() => {
    fetch(curso5file)
      .then((response) => response.text())
      .then((textextracted) => {
        setCurso5({ textcontent: textextracted });
      });
  }, []);

  const toggleteachingclass = function (event) {
    const target = event.currentTarget;
    const actualtarget = event.target;
    const actualtargetparent = actualtarget.parentElement;
    if (actualtargetparent.classList.contains("catalogtitles")) {
      const caret = actualtargetparent.querySelector("i");
      if (caret.classList.contains("la-angle-down")) {
        caret.classList.replace("la-angle-down", "la-angle-up");
      } else {
        caret.classList.replace("la-angle-up", "la-angle-down");
      }
    } else if (actualtarget.classList.contains("catalogtitles")) {
      const caret = actualtarget.querySelector("i");
      if (caret.classList.contains("la-angle-down")) {
        caret.classList.replace("la-angle-down", "la-angle-up");
      } else {
        caret.classList.replace("la-angle-up", "la-angle-down");
      }
    }
    const teachingcontent = target.querySelector(".teachingcontent");
    if (
      teachingcontent !== null &&
      teachingcontent.classList.contains("teachinghide")
    ) {
      teachingcontent.classList.replace("teachinghide", "teachingshow");
      target.title = "";
      setTimeout(() => {
        target.querySelector(".teachingshow").style.opacity = "1";
      }, 50);
    } else {
      teachingcontent.classList.replace("teachingshow", "teachinghide");
      target.title = "Mostrar Curso";
    }
  };

  return (
    <div className="content">
      <div className="landingpagetopworkareas">
        <h1 id="teachingtitleexterior">Formação</h1>
        <div id="teachingtext" className="pagetextcontent teachingjustify">
          <h1 id="teachingtitleinterior">Formação</h1>
          <ReactMarkdown children={introduction.textcontent} />
          <p>
            Cientes da complexidade crescente dos temas das Relações Laborais, o
            nosso escritório está preparado para gerir as crescentes
            necessidades de apoio aos nossos clientes (Direito do Trabalho,
            Direito da Segurança Social e Gestão Administrativa de Recursos
            Humanos). Com contactos privilegiados com Profissionais
            Qualificados, Consultores, Mestres nas áreas do Direito e Formadores
            com CPP, oferecemos apoio específico e Formação On-Line ou
            presencial. Os temas abordados na formação estão descritos no{" "}
            <Link to="#catalogo">Catálogo de Formação</Link>.
          </p>
        </div>
        <img
          srcSet={`${livros24k} 3840w, ${livros2fhd} 1920w, ${livros2hd} 1280w, ${livros21600} 1600w, ${livros21366} 1366w, ${livros21024} 1024w, ${livros2768} 768w, ${livros2640} 640w`}
          sizes="(max-width: 640px) 640px, (min-width: 3840px) 3840px, 1920px, 1280px, 1600px, 1366px, 1024px, 768px"
          src={livros2fhd}
          alt="books"
          className="teachingphoto"
          id="teachingimage"
        />
      </div>
      <div className="teachingmainsection">
        <div className="bonecos">
          <img
            src={bonecos}
            alt="teaching representation"
            className="bonecos"
          />
        </div>
        <div className="catalogue">
          <h2 id="catalogo">
            Catálogo de formação | Direito do Trabalho – Gestão Administrativa
            de Recursos Humanos – S.H.S.T.
          </h2>
          <div
            className="course"
            onClick={toggleteachingclass}
            title="Mostrar Curso"
          >
            <div className="catalogtitles">
              <div className="catalogletters">1</div>
              <h3 id="catalogocursoA">
                Curso Prático de Direito do Trabalho na Gestão Empresarial (16
                Horas, ou cursos breves de 8 ou 4 Horas)
              </h3>
              <i className="las la-angle-down caretdown"></i>
            </div>
            <div className="teachinghide teachingcontent ">
              <ReactMarkdown children={curso1.textcontent} />
            </div>
          </div>
          <div
            className="course"
            onClick={toggleteachingclass}
            title="Mostrar Curso"
          >
            <div className="catalogtitles">
              <div className="catalogletters">2</div>
              <h3 id="catalogocursoB">
                O Direito da Segurança, Higiene e Saúde no Trabalho (16 Horas,
                ou cursos breves de 8 ou 4 Horas)
              </h3>
              <i className="las la-angle-down caretdown"></i>
            </div>
            <div className="teachinghide teachingcontent ">
              <ReactMarkdown children={curso2.textcontent} />
              <ol start="7">
                <li>Responsabilidades pelo incumprimento de normas SHST:</li>
                <ul>
                  <li>a responsabilidade disciplinar;</li>
                  <li>a responsabilidade criminal;</li>
                  <li>a responsabilidade civil;</li>
                  <li>a responsabilidade contraordenacional.</li>
                </ul>
              </ol>
            </div>
          </div>
          <div
            className="course"
            onClick={toggleteachingclass}
            title="Mostrar Curso"
          >
            <div className="catalogtitles">
              <div className="catalogletters">3</div>
              <h3 id="catalogocursoC">
                Procedimento Disciplinar – Tramitação, Direitos, Deveres e
                Impugnação (4 Horas)
              </h3>
              <i className="las la-angle-down caretdown"></i>
            </div>
            <div className="teachinghide teachingcontent ">
              <p>
                Infração Disciplinar; Deveres Trab.; Sanções; Prazos; Fases;
                Tipos de Processos; Abusos; Impugnação; casos práticos; análise
                de jurisprudência.
              </p>
            </div>
          </div>
          <div
            className="course"
            onClick={toggleteachingclass}
            title="Mostrar Curso"
          >
            <div className="catalogtitles">
              <div className="catalogletters">4</div>
              <h3 id="catalogocursoD">
                Riscos Psicossociais - o assédio moral e sexual no trabalho (4
                Horas)
              </h3>
              <i className="las la-angle-down caretdown"></i>
            </div>
            <div className="teachinghide teachingcontent ">
              <p>
                Introdução; o assédio moral no trabalho; consequências do
                assédio; o assédio na ordem jurídica; meios de reacção do
                empregador/vítima; casos práticos / análise de jurisprudência.
              </p>
            </div>
          </div>
          <div
            className="course"
            onClick={toggleteachingclass}
            title="Mostrar Curso"
          >
            <div className="catalogtitles">
              <div className="catalogletters">5</div>
              <h3 id="catalogocursoE">
                Formação do Contrato e Modalidades de Contrato de Trabalho (4
                Horas)
              </h3>
              <i className="las la-angle-down caretdown"></i>
            </div>
            <div className="teachinghide teachingcontent ">
              <p>
                Dever Informação; Período Experimental; Actividade do
                trabalhador; Trabalhador Estrangeiro; Trabalhador-Estudante;
                Contrato a Termo; Contrato Curta Duração; Trabalho a Tempo
                Parcial; Trabalho Intermitente; Comissão de Serviço,
                Teletrabalho e Trabalho Temporário.
              </p>
            </div>
          </div>
          <div
            className="course"
            onClick={toggleteachingclass}
            title="Mostrar Curso"
          >
            <div className="catalogtitles">
              <div className="catalogletters">6</div>
              <h3 id="catalogocursoF">
                Local de Trabalho, Tempo de Trabalho, Retribuição e vicissitudes
                na Execução do Contrato (4 Horas)
              </h3>
              <i className="las la-angle-down caretdown"></i>
            </div>
            <div className="teachinghide teachingcontent ">
              <p>
                Transferência temporária; definitiva; Período Normal Trabalho;
                Horário Trabalho; Registo Tempo Trabalho; Adaptabilidade; Banco
                Horas; Horário Concentrado; Isenção horário Trabalho; Trabalho
                por Turnos; Trabalho Suplementar; Trabalho Nocturno;
                Férias/Feriados/Faltas; Retribuição Horária; Parcelas
                Retributivas; Descontos/Penhora; Recibo Vencimento, Declaração
                de Remunerações; Licença sem retribuição; Pré-reforma;
                Transmissão da Empresa ou Estabelecimento; Redução da actividade
                e suspensão do contrato; Cedência Ocasional/cessão de posição
                contratual.
              </p>
            </div>
          </div>
          <div
            className="course"
            onClick={toggleteachingclass}
            title="Mostrar Curso"
          >
            <div className="catalogtitles">
              <div className="catalogletters">7</div>
              <h3 id="catalogocursoG">
                O Trabalhador e o Empregador na Relação Laboral (4 Horas)
              </h3>
              <i className="las la-angle-down caretdown"></i>
            </div>
            <div className="teachinghide teachingcontent ">
              <p>Direitos, Deveres e Garantias das partes</p>
            </div>
          </div>
          <div
            className="course"
            onClick={toggleteachingclass}
            title="Mostrar Curso"
          >
            <div className="catalogtitles">
              <div className="catalogletters">8</div>
              <h3 id="catalogocursoH">
                As principais alterações ao Código do Trabalho desde o ano de
                2017 (4 Horas)
              </h3>
              <i className="las la-angle-down caretdown"></i>
            </div>
            <div className="teachinghide teachingcontent ">
              <p>
                Lei nº 73/2017, 16/08 (assédio); Lei nº 14/2018, 19/03
                (transmissão de estabelecimento); Lei nº 90/2019, de 04/09-14ª
                alteração ao CT, reforço da parentalidade; Lei nº 93/2019, de
                04/09-15ª alteração ao CT, contrato a termo, formação, período
                experimental; Lei nº 18/2021, 08/04, transmissão de empresa ou
                estabelecimento; Lei nº 83/2021, de 06/12, modifica o regime do
                teletrabalho; Lei nº 1/2022, de 03/01, alarga o período de
                faltas justificadas em caso de falecimento de descendente.
              </p>
            </div>
          </div>
          <div
            className="course"
            onClick={toggleteachingclass}
            title="Mostrar Curso"
          >
            <div className="catalogtitles">
              <div className="catalogletters">9</div>
              <h3 id="catalogocursoI">
                O Secretariado Jurídico e noções básicas de Direito Civil,
                Laboral e Penal (8 Horas)
              </h3>
              <i className="las la-angle-down caretdown"></i>
            </div>
            <div className="teachinghide teachingcontent ">
              <ReactMarkdown children={curso4.textcontent} />
            </div>
          </div>
          <div
            className="course"
            onClick={toggleteachingclass}
            title="Mostrar Curso"
          >
            <div className="catalogtitles">
              <div className="catalogletters">10</div>
              <h3 id="catalogocursoJ">
                Outros temas a acordar os respectivos termos
              </h3>
              <i className="las la-angle-down caretdown"></i>
            </div>
            <div className="teachinghide teachingcontent ">
              <ReactMarkdown children={curso5.textcontent} />
            </div>
          </div>
        </div>
      </div>
      <div className="secondarysection">
        <ReactMarkdown children={footnotes.textcontent} />
      </div>
      <div className="homecontact">
        <Link to="/contacto">Contacto</Link>
      </div>
    </div>
  );
};

export default Teaching;
